<template>
  <div class="top-header-wrapper">
    <v-app-bar :elevation="0" app clipped-left flat>
      <div
        class="top-bar-burger"
        @click.stop="handleHamburgerIconClick"
        :style="{
          'margin-left': `${!mini ? 4 : 0}px`,
          cursor: 'pointer',
        }"
      >
        <v-icon :color="mini ? 'black' : 'blue'" :disabled="updatingNavbar">
          {{ collapseMenuIcon }}
        </v-icon>
      </div>
      <v-toolbar-title>
        <div
          v-if="getCurrentSubscriptionLogo"
          class="logo"
          :style="{ 'margin-left': `${!mini ? '15px' : '25px'}` }"
        >
          <img
            :src="getCurrentSubscriptionLogo"
            alt
            width="90%"
            height="85%"
            max-height="60px"
            @click="handleNavigateToMainPage"
          />
        </div>
        <div
          v-else
          class="logo-default"
          :style="{ 'margin-left': `${!mini ? '0px' : '16px'}` }"
        >
          <img
            src="/assets/img/prezent_logo.svg"
            alt
            width="90%"
            height="85%"
            max-height="60px"
            @click="handleNavigateToMainPage"
          />
        </div>
      </v-toolbar-title>
      <span v-if="isInternalOnly">
        <v-icon slot="icon" color="#e33f3fde" size="36">
          mdi-alert-octagram
        </v-icon>
        For temporary use only, until v0.9 launch
      </span>
      <v-spacer />
      <div class="right-nav">
        <GlobalSearch />
        <NavbarActions />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { mdiMenu } from '@mdi/js';
// import { Storage } from 'aws-amplify';
import DialogService from '../services/DialogService';
import { getFileURL } from '@/utils/calyrex';
import GlobalSearch from './navbar/GlobalSearch.vue';
import NavbarActions from './navbar/NavbarActions.vue';
// import { Storage } from "aws-amplify";
import EventBus from './common/event-bus';
import usersApi, { getTDData } from '../API/users-api';
import { workerApp } from '../workers/worker-app/workerApp';
import { getLoggedInUserToken } from '../utils/api';
import { templateDesignerWorker } from '../workers/template-designer-worker/templateDesignerWorker.js';
import { TemplateDesignerService } from '../pages/components/TemplateDesigner/TemplateDesignerService.js';
import { designerTemplateLimit } from '../pages/components/TemplateDesigner/constants.js';
// import axios from "axios";
export default {
  name: 'Header',
  mixins: [DialogService],
  components: {
    GlobalSearch,
    NavbarActions,
  },
  props: {
    updateContent: {
      type: Function,
      default: () => ({}),
    },
    updateMini: {
      type: Function,
      default: () => ({}),
    },
    minimize: {
      type: Function,
      default: () => ({}),
    },
    mini: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isInternalOnly: process.env.VUE_APP_IS_INTERNAL_ONLY_BUILD === 'true',
      collapseMenuIcon: mdiMenu,
      updatingNavbar: false,
      /* UPDATE: this array with the feature route name property when 
      its landing page or route requires navbar collpase */
      featuresRequireNavbarCollapse: [
        'build',
        'generate',
        'compliance',
        'fingerprint',
        'synthesis',
        'redesign',
        'Prezentations',
        'overnightPrezentations',
        'best-practice-library',
        'slides',
        'learn',
        'accelerators',
      ],
      tdWorker: null,
    };
  },
  computed: {
    ...mapState('tourStore', ['notifications']),
    ...mapState('users', [
      'currentUser',
      'allUsers',
      'allAudiences',
      'isTrialExpired',
      'trialDaysLeft',
      'startLoadingSlidesFlag',
      'themes',
      'currentActiveSubscription',
    ]),
    ...mapState('templateDesigner', ['tdOverallStatus']),
    // ...mapState("users", ["sessionKey"]),
    // ...mapState("learndata", ["moduleItems"]),
    ...mapState('prezentations', ['allPrezentations']),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    ...mapGetters('templateDesigner', [
      'areAllJobsFailed',
      'areAllJobsCompleted',
    ]),
    getCurrentSubscriptionLogo() {
      const planIdToLogoMap = {
        12345: '/assets/img/prezent_Trial_logo.svg',
        12347: '/assets/img/prezent_Enterprise_logo.svg',
        12348: '/assets/img/prezent_Standard_logo.svg',
        12349: '/assets/img/prezent_Premium_logo.svg',
      };

      return planIdToLogoMap[this.currentActiveSubscription?.planID] || null;
    },
    templateDesignerService() {
      return new TemplateDesignerService();
    },

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },
  },
  methods: {
    // ...mapActions("users", ["setSessionKey"]),
    // ...mapActions("learndata", ["loadLearningData"]),
    ...mapActions('tourStore', ['setTour', 'setNotifications']),
    ...mapActions('prezentations', ['fetchAllPrezentations']),
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('templateDesigner', ['setTDData', 'setTDOverallStatus']),

    handleNavigateToMainPage() {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        this.setSlideViewConfirmationModalState(true);
        this.setRouteToNavigate({
          content: '/home/main',
          type: 'to_main_page',
          action: 'push',
          pushAction: true,
        });
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
        if (!this.$route.name.includes('main')) {
          this.$router.push('/home/main');
        }
      }
    },

    getProfileImage() {
      if (this.currentUser.user.profileImage) {
        getFileURL(null, this.currentUser.user.profileImage, null, 'storage')
          .then((s3obj) => {
            this.profileImage = s3obj;
          })
          .catch((err) => {
            console.log(err);
            this.profileImage = '/assets/img/home-header-profile.svg';
          });
      }
    },
    handleVisibility() {
      if (document.visibilityState === 'hidden') {
        if (this.tdWorker !== null) {
          this.stopTDWorker();
        }
        console.log('Navigated away, worker two closed');
      } else {
        if (this.tdWorker !== null) {
          this.stopTDWorker();
        }
        if (this.tdOverallStatus === 'inprogress') {
          this.initializeTDData();
        }

        console.log('user active, worker two started');
      }
    },
    stopTDWorker() {
      this.tdWorker?.terminate();
    },
    async startTDWithWorker() {
      if (window.Worker) {
        this.tdWorker = workerApp(templateDesignerWorker);
        const loggedInUserToken = await getLoggedInUserToken();
        this.tdWorker.postMessage({
          token: loggedInUserToken,
        });
        this.tdWorker.addEventListener('message', async (e) => {
          if (e.data === 'worker unauthorized') {
            this.stopTDWorker();
            window.location = '/signin/10001';
          } else if (e.data.length) {
            const designerThemes = e.data;
            const tdData = designerThemes.filter(
              (designerTheme) => designerTheme.jobSubmitted,
            );
            this.setTDData(tdData);
            if (designerThemes.length === designerTemplateLimit) {
              const isDoneStatus = designerThemes.every(
                (item) =>
                  item.status === 'completed' || item?.status === 'failed',
              );
              // Stop the worker if all jobs are completed or failed
              if (isDoneStatus) {
                // this.updateUserProfile(e);
                if (this.areAllJobsCompleted) {
                  this.setTDOverallStatus('completed');
                } else if (this.areAllJobsFailed) {
                  this.setTDOverallStatus('failed');
                }

                if (this.tdWorker !== null) {
                  this.stopTDWorker();
                }
              }
            }
          }
        });
      }
    },
    initializeTDData() {
      getTDData()
        .then((resp) => {
          const designerThemes = resp.data;
          if (designerThemes.length) {
            const isInProgressStatus = designerThemes.some(
              (item) => item.status === 'inprogress',
            );
            const submittedJobs = designerThemes.filter(
              (item) => item.jobSubmitted,
            );
            this.setTDData(submittedJobs);
            if (isInProgressStatus) {
              this.setTDOverallStatus('inprogress');
              this.startTDWithWorker();
            } else if (this.areAllJobsCompleted) {
              this.setTDOverallStatus('completed');
            } else if (this.areAllJobsFailed) {
              this.setTDOverallStatus('failed');
            } else if (this.tdWorker !== null) {
              this.stopTDWorker();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // serializeParam(params) {
    //   return Object.entries(Object.assign({}, params))
    //     .map(([key, value]) => `${key}=${value}`)
    //     .join("&");
    // },
    // setLabel(item) {
    //   return item.name;
    // },
    // getLearnLevel(id) {
    //   let parts = id.split("_");
    //   let number = parts[1];
    //   let level = number.slice(0, 2);
    //   return Math.floor((parseInt(level) - 1) / 6);
    // },
    // getLearnModule(id) {
    //   let parts = id.split("_");
    //   let number = parts[1];
    //   let item = number.slice(0, 2);
    //   return parseInt(item) - this.getLearnLevel(id) * 6;
    // },

    async handleHamburgerIconClick() {
      this.updatingNavbar = true;
      if (this.featuresRequireNavbarCollapse.indexOf(this.$route.name) !== -1) {
        this.updateMini();
        const userdata = {
          navbarExpanded: this.mini,
        };
        await usersApi.methods
          .updateUserProfile(userdata)
          .then((resp) => {
            let userInfo = this.currentUser;
            userInfo = {
              ...userInfo,
              user: {
                ...userInfo.user,
                navbarExpanded: resp.navbarExpanded,
              },
            };
            this.setCurrentUser(userInfo);
          })
          .catch((err) => console.log(err));
      } else {
        this.updateMini();
        const userdata = {
          navbarExpanded: !this.currentUser.user.navbarExpanded,
        };
        await usersApi.methods
          .updateUserProfile(userdata)
          .then(() => {
            let userInfo = this.currentUser;
            userInfo = {
              ...userInfo,
              user: {
                ...userInfo.user,
                navbarExpanded: !this.currentUser.user.navbarExpanded,
              },
            };
            this.setCurrentUser(userInfo);
          })
          .catch((err) => console.log(err));
      }
      this.updatingNavbar = false;
    },
  },

  async beforeMount() {
    // this.isShown = this.notifications && this.notifications.length > 0;
    this.getProfileImage();
    if (window.Worker) {
      this.initializeTDData();
    }
    // TODO(angie): move to learn?
    // let payload = {
    //   userID: this.currentUser.id,
    //   organizationID: this.currentUser.organizationID,
    // };
    // this.loadLearningData(payload);
    // this.fetchAllPrezentations(this.currentUser.id);
  },

  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibility, false);
    this.setTDData([]);
    EventBus.$on('PROFILE_IMAGE', this.getProfileImage);
    EventBus.$on('MINIMIZE_LEFTNAV', this.minimize);
  },
  beforeDestroy() {
    if (this.tdWorker !== null) {
      this.stopTDWorker();
    }
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibility,
      false,
    );
    EventBus.$off('PROFILE_IMAGE', this.getProfileImage);
    EventBus.$off('MINIMIZE_LEFTNAV', this.minimize);
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.top-header-wrapper {
  height: 68px;
}

::v-deep .v-toolbar__content {
  padding-left: 28px;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%) !important;
  min-height: 68px;
  padding-right: 21px;
  .logo,
  .logo-default {
    margin-top: 5px;
    max-height: 60px !important;
    max-width: 200px;
    min-width: 200px;
    cursor: pointer;
  }

  .logo img {
    width: 60%;
  }
  .logo-default img {
    width: 90%;
  }

  .right-nav {
    align-items: center;
    align-content: flex-end;
    display: flex;
    gap: 20px;
  }
}
</style>
